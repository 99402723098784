import { Button, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { Product } from '../constants';
import { productOptionsForDubPoint } from '../helpers';
import { useFetchDubberAccount, useFetchDubberDubPoint, useUpdateDubberDubPoint } from '../hooks';
import { DubberPolicies } from '../policies';
import { dubberDubPointViewUrl } from '../urls';
import DubPointUpdateForm, { FormValues } from './DubPointUpdateForm';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    button: {
        marginLeft: theme.spacing(1)
    },
    subheading: {
        display: 'flex'
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

const DubPointUpdatePage = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const groupId = props.match.params['groupId'];
    const accountId = props.match.params['accountId'];
    const dubPointId = props.match.params['dubPointId'];

    const [fetchAccount, isFetchingAccount, account, fetchAccountError] = useFetchDubberAccount();
    const [fetchDubPoint, isFetchingDubPoint, dubPoint, fetchDubPointError] = useFetchDubberDubPoint();
    const [updateDubPoint, isUpdatingDubPoint, dubPointUpdated, updateDubPointError, updateDubPointFieldErrors] = useUpdateDubberDubPoint();

    const [formValues, setFormValues] = useState<FormValues>({
        product: ''
    });

    useNav('dubber', 'accounts');

    useProgressEffects(
        isFetchingAccount || isFetchingDubPoint,
        fetchAccountError || fetchDubPointError || updateDubPointError
    );

    useEffect(() => {
        fetchAccount(groupId, accountId);
        fetchDubPoint(groupId, accountId, dubPointId);
    }, [accountId, dubPointId]);

    // Update form defaults on load
    useEffect(() => {
        if (dubPoint) {
            setFormValues({
                product: dubPoint.product
            });
        }
    }, [dubPoint]);

    // Determine service & product options based off dub point details
    const products = useMemo(() => {
        let options: Product[] = [];

        if (dubPoint) {
            options = productOptionsForDubPoint(dubPoint);
        }

        return options;
    }, [dubPoint]);

    const handleUpdate = (field: keyof FormValues, value: string | boolean) => {
        setFormValues({
            ...formValues,
            [field]: String(value)
        });
    };

    // Pressing cancel goes back to the dub point view
    const handleCancel = () => {
        history.push(dubberDubPointViewUrl(groupId, accountId, dubPointId));
    };

    const handleSubmit = () => {
        updateDubPoint(groupId, accountId, dubPointId, formValues)
    };

    // Redirect to view on update
    useEffect(() => {
        if (dubPointUpdated) {
            dispatch(appWindowAddNotification('Dub Point updated.', 'success'));
            history.push(dubberDubPointViewUrl(groupId, accountId, dubPointId));
        }
    }, [dubPointUpdated]);

    return (
        <div className={classes.root}>
            {dubPoint !== null && account !== null
                && <>
                    <PageHeader text="Update Dub Points" subtext={dubPointId} />

                    <Paper className={classes.paper}>
                        <DubPointUpdateForm
                            productOptions={products}
                            formValues={formValues}
                            isSubmitting={isUpdatingDubPoint}
                            onUpdate={handleUpdate}
                            onEnter={() => handleSubmit()}
                            errorMessage={updateDubPointError}
                            fieldErrorMessages={updateDubPointFieldErrors}
                        />
                    </Paper>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className={classes.button}
                            color="inherit"
                            variant="contained"
                            disabled={isUpdatingDubPoint}
                            onClick={handleCancel}>Cancel</Button>

                        <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            disabled={isUpdatingDubPoint}
                            onClick={handleSubmit}>Update</Button>
                    </Grid>
                </>
            }
        </div>
    );
};

export default withPolicyRestriction(DubPointUpdatePage, DubberPolicies.CanManageDubPoints);