import { ApiCaller } from '../../../api';

/*
 * GET /api/operator-connect/bandwidth-subaccounts
 *
 * Get all subaccounts within the Operator Connect Bandwidth account
 */

export interface SubAccount {
    id: number;
    accountId: number;
    name: string;
    description: string;
}

export const fetchOperatorConnectBandwidthSubAccounts = async (api: ApiCaller): Promise<SubAccount[]> => {
    return await api<SubAccount[]>(`operator-connect/bandwidth-subaccounts`, 'GET');
};