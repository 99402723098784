import { Route } from '@mui/icons-material';
import { Card, CardActionArea, CardContent, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import theme from '../../../../theme';
import { operatorConnectAssignmentViewUrl } from '../../../operator-connect/urls';
import { useFetchOperatorConnectAssignment } from '../../../operator-connect/hooks';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
        width: 275
    },
    serviceName: {
        ...theme.typography.h5,
        color: theme.palette.text.primary
    },
    details: {
        marginTop: theme.spacing(1)
    }
}));

interface Props {
    organizationId: number;
    assignmentId: number;
}

/**
 * A card in the assigned services grid when the service is Operator Connect
 *
 * @param props
 * @constructor
 */
const OperatorConnectCard = (props: Props) => {
    const classes = useStyles();
    const { organizationId, assignmentId } = props;

    const [fetch, isFetching, assignment, error] = useFetchOperatorConnectAssignment();

    const formatCancelDate = useCallback((ts: any) => moment(ts).utc().format('MM/DD/YYYY'), []);

    useEffect(() => {
        fetch(organizationId, assignmentId);
    }, [organizationId, assignmentId])

    return (
        <Card className={classes.root}>
            <CardActionArea component={React.forwardRef((props, ref) => <Link to={operatorConnectAssignmentViewUrl(organizationId, assignmentId)} {...props} />)}>
                <CardContent>
                    <Typography className={classes.serviceName} component="h4">
                        <Route fontSize="small" /> Calling for MS Teams
                    </Typography>

                    {!isFetching && assignment != null &&
                        <Typography className={classes.details} component="p">
                            Sub Account ID: {assignment.bandwidthSubAccountId}
                            {assignment.dubberAccountId &&
                                <><br />Dubber Acct: {assignment.dubberAccountId.substring(0, 8)}...</>
                            }
                            {!assignment.isActive && <><br />Cancelled: {formatCancelDate(assignment.cancelledAt)}</>}
                        </Typography>
                    }
                </CardContent>
            </CardActionArea>

            {isFetching && <LinearProgress variant="indeterminate" color="primary" />}
        </Card>
    );
};

export default OperatorConnectCard;