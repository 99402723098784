import { Button, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Pagination from '../../../components/Pagination';
import { DEFAULT_PAGE_SIZE, MAIN_WIDTH } from '../../../constants';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { useFetchBandwidthAccounts, useFetchBandwidthLocations } from '../hooks';
import { BandwidthPolicies } from '../policies';
import LocationsTable from '../sub-accounts/LocationsTable';
import { bandwidthLocationCreateUrl } from '../urls';
import SearchForm from './SearchForm';
const queryString = require('query-string');

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    addButton: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    }
}));

interface Props extends RouteComponentProps<any> {
}

// Page for listing all locations within a Bandwidth sub-account
const LocationIndexPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [selected, setSelected] = useState<[string, string] | null>(null);

    const [page, setPage] = useState(1);

    const [fetchAccounts, isFetchingAccounts, accounts, accountsErrorMessage] = useFetchBandwidthAccounts();
    const [fetchLocations, isFetchingLocations, locations, locationsErrorMessage] = useFetchBandwidthLocations();

    // Update navigation
    useNav('bandwidth', 'locations');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetchingAccounts || isFetchingLocations,
        accountsErrorMessage || errorMessage || locationsErrorMessage
    );

    // Fetch accounts on load
    useEffect(() => {
        fetchAccounts();
    }, [])

    const handleError = (message: string) => setErrorMessage(message);

    const handleSelected = (accountId: string, subAccountId: string) => {
        setSelected([accountId, subAccountId]);
    };

    // Fetch locations when subaccount selected
    useEffect(() => {
        if (selected) {
            fetchLocations(selected[0], selected[1]);
        }
    }, [selected]);

    const selectedAccount = accounts?.find(a => selected !== null && a.id === selected[0]) || null;

    // Order locations by name
    const sortedLocations = useMemo(() => (locations || []).sort((a, b) => a.name.localeCompare(b.name)), [locations]);

    // Pagination locations
    const pagedLocations = useMemo(() => {
        return (locations || [])
            .slice((page - 1) * DEFAULT_PAGE_SIZE, page * DEFAULT_PAGE_SIZE);
    }, [sortedLocations, page]);

    const canAddLocation = !selectedAccount?.locationRestricted

    return (
        <div className={classes.root}>
            <PageHeader text="Bandwidth" subtext="Locations" />

            {!isFetchingAccounts && accounts &&
                <>
                    <Paper className={classes.paper}>
                        <SearchForm
                            disabled={isFetchingLocations}
                            accounts={accounts}
                            onError={handleError}
                            onSelected={handleSelected}
                        />
                    </Paper>

                    <Paper>
                        {!isFetchingLocations && locations && selected && selectedAccount &&
                            <>
                                {canAddLocation && authorizedFor(BandwidthPolicies.CanManageLocations, session.roles) &&
                                    <Button
                                        className={classes.addButton}
                                        variant="text"
                                        color="primary"
                                        component={React.forwardRef((props, ref) => <Link to={bandwidthLocationCreateUrl(selected[0], selected[1])} {...props} />)}>
                                        Add New
                                    </Button>
                                }

                                {locations.length === 0
                                    && <div className={classes.noResultsContainer}>
                                        <Typography color="textSecondary" align="center">No locations found.</Typography>
                                    </div>
                                }

                                {locations.length > 0
                                    && <>
                                        <LocationsTable account={selectedAccount} subAccountId={selected[1]} locations={pagedLocations} />

                                        <Pagination
                                            totalItems={sortedLocations.length}
                                            pageSize={DEFAULT_PAGE_SIZE}
                                            page={page}
                                            disabled={false}
                                            onChange={page => setPage(page)} />
                                    </>
                                }
                            </>}
                    </Paper>
                </>
            }
        </div>
    );
};

export default LocationIndexPage;