import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { parse, ParsedQuery } from 'query-string';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Pagination from '../../../components/Pagination';
import { DEFAULT_PAGE_SIZE, MAIN_WIDTH } from '../../../constants';
import history from '../../../history';
import theme from '../../../theme';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { Status } from './api';
import { useFetchOperatorConnectOrganizations } from './hooks';
import OrganizationSearchBar from './OrganizationSearchBar';
import OrganizationTable from './OrganizationTable';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    noResultsContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    }
}));

interface Props extends RouteComponentProps<any> {
}

interface Form {
    search: string;
    status: Status;
    page: number;
    limit: number;
}

const compareForm = (a: Form, b: Form) =>
    a.search == b.search
    && a.status == b.status
    && a.page == b.page
    && a.limit == b.limit;

const formFromQuery = (query: ParsedQuery<string>): Form => {
    const searchQuery = String(query['search'] || '');
    const statusQuery = String(query['status'] || 'active');

    const pageQuery = parseInt(String(query['page'] || 1));
    const limitQuery = parseInt(String(query['limit'] || DEFAULT_PAGE_SIZE));

    return {
        search: searchQuery,
        status: statusQuery as Status,
        page: pageQuery,
        limit: limitQuery
    }
};

// Submitting the search will update the query string and force a fetch
const handleSubmitSearch = (search: string, status: Status, limit: number) => {
    var query: Record<string, string> = {
        status: String(status),
        limit: String(limit),
        page: "1"
    };

    if (search && search.length > 0) {
        query['search'] = search;
    }

    const params = new URLSearchParams(query);

    history.push('?' + params.toString());
}

// Page for listing all oprganizations with Direct Routing service
const OrganizationIndexPage = (props: Props) => {
    const classes = useStyles();
    const [form, setForm] = useState<Form>({
        search: '',
        status: 'active',
        page: 1,
        limit: DEFAULT_PAGE_SIZE
    });
    const [fetch, isFetching, organizations, errorMessage] = useFetchOperatorConnectOrganizations();

    // Parse query string
    const params = parse(location.search);

    // Refresh list if current form settings don't match the query string
    var queryForm = formFromQuery(params);

    if (!compareForm(form, queryForm)) {
        setForm(queryForm);
    }

    // Update navigation
    useNav('operator-connect', 'organizations');

    // Display progress bar and error messages for the fetch
    useProgressEffects(
        isFetching,
        errorMessage
    );

    // Trigger fetch when form is updated
    useEffect(() => {
        fetch(form.search, form.status, form.page, form.limit);
    }, [form]);

    return (
        <div className={classes.root}>
            <PageHeader text="Calling for MS Teams" subtext="Organizations w/ Service" />

            <Paper>
                <OrganizationSearchBar
                    search={form.search}
                    status={form.status}
                    limit={form.limit}
                    onSubmitSearch={handleSubmitSearch}
                />

                {organizations !== null
                    && organizations.totalItems === 0
                    && <div className={classes.noResultsContainer}>
                        <Typography color="textSecondary" align="center">No organizations with Operator Connect found.</Typography>
                    </div>
                }

                {organizations !== null
                    && organizations.totalItems > 0
                    && <>
                        <OrganizationTable items={organizations.items} />

                        <Pagination
                            totalItems={organizations.totalItems}
                            pageSize={organizations.pageSize}
                            page={organizations.page}
                            disabled={isFetching}
                            additionalParams={{
                                search: form.search,
                                status: form.status,
                                limit: form.limit
                            }}
                            onChange={() => null} />
                    </>
                }
            </Paper>
        </div>
    );
};

export default OrganizationIndexPage;