import { FormControl, Input, InputLabel, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { OrganizationDetails } from '../../organizations/api';
import { SubAccount } from '../bandwidth-subaccounts/api';
import { DubberAccount } from '../dubber-accounts/api';
import { UpdateAssignment } from './api';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    organization: OrganizationDetails;
    bandwidthSubAccountOptions: SubAccount[];
    dubberAccountOptions: DubberAccount[];
    formValues: UpdateAssignment,
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    setFormValues: (form: UpdateAssignment) => void;
}

export const formComplete = (organization: OrganizationDetails, form: UpdateAssignment): boolean => {
    return form.bandwidthSubAccountId !== null
        && form.dubberAccountId !== '';
};

/**
 * Operator Connect update assignment form
 * @param props
 * @constructor
 */
const UpdateAssignmentForm = (props: Props) => {
    const { bandwidthSubAccountOptions, dubberAccountOptions, errorMessage, fieldErrorMessages, formValues, setFormValues, isSubmitting } = props;
    const classes = useStyles();

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const displayError = errorMessage;

    return (
        <>
            {displayError && <Typography variant="body1" color="error">{displayError}</Typography>}

            <FormControl className={classes.formControl} fullWidth required error={!isValid('bandwidthSubAccountId')}>
                <InputLabel htmlFor="bandwidthSubAccountId">Bandwidth Sub-Account</InputLabel>

                <Select
                    id="bandwidthSubAccountId"
                    margin="none"
                    native={true}
                    disabled={isSubmitting}
                    value={formValues.bandwidthSubAccountId || ''}
                    onChange={evt => setFormValues({ ...formValues, bandwidthSubAccountId: evt.target.value ? parseInt(String(evt.target.value)) : null })}
                    input={<Input name="bandwidthSubAccountId" id="bandwidthSubAccountId" />}>

                    <option key="" value="" />

                    {bandwidthSubAccountOptions.map(acct => <option key={acct.id} value={acct.id}>{acct.name} ({acct.id})</option>)}
                </Select>

                <ValidationMessage field="bandwidthSubAccountId" />
            </FormControl>

            <FormControl className={classes.formControl} fullWidth error={!isValid('dubberAccountId')}>
                <InputLabel htmlFor="dubberAccountId" shrink={true}>Dubber Account</InputLabel>

                <Select
                    id="dubberAccountId"
                    margin="none"
                    native={true}
                    disabled={isSubmitting}
                    value={formValues.dubberAccountId ? (formValues.dubberGroupId + '/' + formValues.dubberAccountId) : ''}
                    input={<Input name="dubberAccountId" id="dubberAccountId" />}
                    onChange={evt => {
                        var val = evt.target.value ? String(evt.target.value) : null

                        if (val === null) {
                            setFormValues({
                                ...formValues,
                                dubberGroupId: undefined,
                                dubberAccountId: undefined
                            });
                        } else {
                            var split = val.split('/');

                            setFormValues({
                                ...formValues,
                                dubberGroupId: split[0],
                                dubberAccountId: split[1]
                            });
                        }
                    }}>
                    <option key="" value="">None</option>

                    {dubberAccountOptions.map(c => {
                        return <option key={c.groupId + '/' + c.id} value={c.groupId + '/' + c.id}>{c.name} ({c.groupId} / {c.id})</option>;
                    })}
                </Select>

                <ValidationMessage field="dubberAccountId" />
            </FormControl>
        </>
    );
};

export default UpdateAssignmentForm;