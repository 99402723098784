export const OPERATOR_CONNECT_ASSIGNMENT_VIEW_URL = '/organizations/:organizationId/services/operator-connect/:assignmentId';
export const OPERATOR_CONNECT_ASSIGNMENT_UPDATE_URL = '/organizations/:organizationId/services/operator-connect/:assignmentId/update';

export const OPERATOR_CONNECT_ORGANIZATION_INDEX_URL = '/operator-connect/organizations';

export const operatorConnectAssignmentViewUrl = (organizationId: string | number, assignmentId: string | number) =>
    OPERATOR_CONNECT_ASSIGNMENT_VIEW_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));

export const operatorConnectAssignmentUpdateUrl = (organizationId: string | number, assignmentId: string | number) =>
    OPERATOR_CONNECT_ASSIGNMENT_UPDATE_URL.replace(':organizationId', String(organizationId))
        .replace(':assignmentId', String(assignmentId));

export const operatorConnectOrganizationIndexUrl = () => OPERATOR_CONNECT_ORGANIZATION_INDEX_URL;

