import { ApiCaller } from '../../../api';

/*
 * GET /api/operator-connect/dubber-accounts
 *
 * Get all dubber accounts in group for operator connect
 */

export interface DubberAccount {
    id: string;
    groupId: string;
    groupName: string;
    name: string;
}

export const fetchOperatorConnectDubberAccounts = async (api: ApiCaller): Promise<DubberAccount[]> => {
    return await api<DubberAccount[]>('operator-connect/dubber-accounts', 'GET');
};