import * as React from 'react';
import { OrganizationDetails } from '../api';
import { Service, SERVICE_HOSTED_PBX, SERVICE_FAX2MAIL, SERVICE_SIP_TRUNKING, SERVICE_WEBEX, SERVICE_DIRECT_ROUTING, SERVICE_OPERATOR_CONNECT } from '../../../services';
import HostedPbxStep from './HostedPbxStep';
import Fax2MailStep from './Fax2MailStep';
import SipTrunkingStep from './SipTrunkingStep';
import { FieldError } from '../../../api';
import WebexStep from './WebexStep';
import DirectRoutingStep from './DirectRoutingStep';
import OperatorConnectStep from './OperatorConnectStep';

interface Props {
    organization: OrganizationDetails;
    service: Service;
    onReady: (ready: boolean, values: {[key: string]: string | boolean | number | number[] | undefined | null}) => void;
    submitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
}

/**
 * Step two of the service assignment wizard.
 * Displays fields specific to the selected service.
 *
 * @param props
 * @constructor
 */
const StepTwo = (props: Props) => {
    const { organization, service, onReady, submitting, errorMessage, fieldErrorMessages } = props;

    return (
        <>
            {service == SERVICE_DIRECT_ROUTING && <DirectRoutingStep
                organization={organization}
                onReady={onReady}
                submitting={submitting}
                errorMessage={errorMessage}
                fieldErrorMessages={fieldErrorMessages} />}

            {service == SERVICE_HOSTED_PBX && <HostedPbxStep
                organization={organization}
                onReady={onReady}
                submitting={submitting}
                errorMessage={errorMessage}
                fieldErrorMessages={fieldErrorMessages} />}

            {service == SERVICE_FAX2MAIL && <Fax2MailStep
                organization={organization}
                onReady={onReady}
                submitting={submitting}
                errorMessage={errorMessage}
                fieldErrorMessages={fieldErrorMessages} />}

            {service == SERVICE_OPERATOR_CONNECT && <OperatorConnectStep
                organization={organization}
                onReady={onReady}
                submitting={submitting}
                errorMessage={errorMessage}
                fieldErrorMessages={fieldErrorMessages} />}

            {service == SERVICE_SIP_TRUNKING && <SipTrunkingStep
                organization={organization}
                onReady={onReady}
                submitting={submitting}
                errorMessage={errorMessage}
                fieldErrorMessages={fieldErrorMessages} />}

            {service == SERVICE_WEBEX && <WebexStep
                organization={organization}
                onReady={onReady}
                submitting={submitting}
                errorMessage={errorMessage}
                fieldErrorMessages={fieldErrorMessages} />}
        </>
    );
};

export default StepTwo;