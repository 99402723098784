import { Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { serviceName } from '../../../services';
import theme from '../../../theme';
import { platformForDubPoint } from '../helpers';
import { DubPointBase } from './api';
import BroadWorksSummary from './BroadWorksSummary';
import TeamsSummary from './TeamsSummary';

const useStyles = makeStyles(() => ({
    subheading: {
        display: 'flex'
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    dubPoint: DubPointBase;
}

const DubPointServiceSection = (props: Props) => {
    const classes = useStyles();
    const { dubPoint } = props;

    const dubPointPlatform = platformForDubPoint(dubPoint);

    return (
        <>
            {(dubPointPlatform === 'HostedPBX' || dubPointPlatform === 'Webex') &&
                <div className={classes.section}>
                    <div className={classes.subheading}>
                        <Typography variant="h3">{serviceName(dubPointPlatform)}</Typography>
                    </div>

                    <BroadWorksSummary dubPoint={dubPoint} />
                </div>
            }

            {dubPointPlatform === 'Teams' &&
                <div className={classes.section}>
                    <div className={classes.subheading}>
                        <Typography variant="h3">Microsoft Teams</Typography>
                    </div>

                    <TeamsSummary dubPoint={dubPoint} />
                </div>
            }
        </>
    );
};

export default DubPointServiceSection;