import { ApiCaller, PagedResponse } from '../../../api';

/*
 * GET /api/organization/{organizationId}/services/operator-connect
 *
 * Get all operator connect assignments for an organization
 */

export interface Assignment {
    id: number;
    startedAt: string;
    isActive: boolean;
    cancelledAt: string | null;
    bandwidthAccountId: number;
    bandwidthSubAccountId: number;
    dubberGroupId: string;
    dubberAccountId: string;
}

export interface CreateAssignment {
    bandwidthSubAccountId: number | null
    useDubber?: boolean;
    dubberGroupId?: string;
    dubberAccountId?: string;
}

export const fetchOperatorConnectAssignments = async (api: ApiCaller, organizationId: number): Promise<Assignment[]> => {
    return await api<Assignment[]>(`organizations/${organizationId}/services/operator-connect`, 'GET');
};

/*
 * GET /api/organization/{organizationId}/services/operator-connect/{assignmentId}
 *
 * Get operator connect assignment
 */

export const fetchOperatorConnectAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/operator-connect/${assignmentId}`, 'GET');
};

/*
 * POST /api/organization/{organizationId}/services/operator-connect
 *
 * Assign operator connect service to an organization
 */

export const createOperatorConnectAssignment = async (api: ApiCaller, organizationId: number, form: CreateAssignment): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/operator-connect`, 'POST', {
        ...form
    });
};

export interface UpdateAssignment {
    bandwidthSubAccountId: number | null;
    useDubber?: boolean;
    dubberGroupId?: string;
    dubberAccountId?: string;
};

/*
 * PUT /api/organization/{organizationId}/services/operator-connect/{assignmentId}
 *
 * Update operator connect service assignment
 */

export const updateOperatorConnectAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number, form: UpdateAssignment): Promise<Assignment> => {
    return await api<Assignment>(`organizations/${organizationId}/services/operator-connect/${assignmentId}`, 'PUT', {
        ...form
    });
};

/*
 * POST /api/organization/{organizationId}/services/operator-connect/{assignmentId}/cancel-check
 *
 * Check if a operator connect assignment can be cancelled
 */

export interface CancelCheck {
    success: boolean;
    bandwidth: CancelBandwidthCheck;
    hasDubber: boolean;
    dubber: CancelDubberCheck | null;
}

export interface CancelBandwidthCheck {
    success: boolean;
    accountExists: boolean;
    subAccountId: number;
    subAccountName: string | null;
    numberCount: number;
    locationCount: number;
}

export interface CancelDubberCheck {
    success: boolean;
    accountExists: boolean;
    id: string;
    name: string | null;
    userCount: number;
    dubPointCount: number;
}

export const cancelCheckOperatorConnectAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<CancelCheck> => {
    return await api<CancelCheck>(`organizations/${organizationId}/services/operator-connect/${assignmentId}/cancel-check`, 'POST');
};



/*
 * DELETE /api/organization/{organizationId}/services/operator-connect/{assignmentId}
 *
 * Cancel a operator connect service assignment
 */

export const cancelOperatorConnectAssignment = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<{}> => {
    return await api<{}>(`organizations/${organizationId}/services/operator-connect/${assignmentId}`, 'DELETE');
};
