import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppWindow from '../../features/app-window/AppWindow';
import LoginPage from '../../features/auth/LoginPage';
import MfaPage from '../../features/auth/MfaPage';
import DashboardPage from '../../features/dashboard/DashboardPage';
import OrganizationIndexPage from '../../features/organizations/index/OrganizationIndexPage';
import OrganizationViewPage from '../../features/organizations/view/OrganizationViewPage';
import AdministratorCreatePage from '../administrators/create/AdministratorCreatePage';
import AdministratorIndexPage from '../administrators/index/AdministratorIndexPage';
import AdministratorUpdatePage from '../administrators/update/AdministratorUpdatePage';
import {
    ADMINISTRATOR_CREATE_URL,
    ADMINISTRATOR_INDEX_URL,
    ADMINISTRATOR_UPDATE_URL,
    ADMINISTRATOR_VIEW_URL
} from '../administrators/urls';
import AdministratorViewPage from '../administrators/view/AdministratorViewPage';
import { LOGIN_MFA_URL, LOGIN_URL } from '../auth/urls';
import { DASHBOARD_URL } from '../dashboard/urls';

import BandwidthSubAccountIndexPage from '../bandwidth/sub-accounts/SubAccountIndexPage';
import BandwidthSubAccountViewPage from '../bandwidth/sub-accounts/SubAccountViewPage';
import BandwidthLocationIndexPage from '../bandwidth/locations/LocationIndexPage';
import BandwidthLocationViewPage from '../bandwidth/locations/LocationViewPage';
import BandwidthLocationCreatePage from '../bandwidth/locations/LocationCreatePage';
import BandwidthLocationUpdatePage from '../bandwidth/locations/LocationUpdatePage';
import BandwidthNumberIndexPage from '../bandwidth/numbers/index/NumberIndexPage';
import BandwidthNumberViewPage from '../bandwidth/numbers/view/NumberViewPage';
import BandwidthNumberGeneralUpdatePage from '../bandwidth/numbers/update/GeneralUpdatePage';
import BandwidthNumberRoutingUpdatePage from '../bandwidth/numbers/update/RoutingUpdatePage';
import BandwidthNumberPasscodeUpdatePage from '../bandwidth/numbers/update/PasscodeUpdatePage';
import BandwidthNumberE911UpdatePage from '../bandwidth/numbers/update/E911UpdatePage';
import BandwidthNumberOutboundCallerIdUpdatePage from '../bandwidth/numbers/update/OutboundCallerIdUpdatePage';
import BandwidthNumberMovePage from '../bandwidth/numbers/update/MoveNumberPage';
import BandwidthOrderIndexPage from '../bandwidth/orders/OrderIndexPage';
import BandwidthNewNumberOrderViewPage from '../bandwidth/orders/new-numbers/NewNumberViewPage';
import BandwidthPortInOrderViewPage from '../bandwidth/orders/port-ins/PortInViewPage';
import BandwidthE911OrderViewPage from '../bandwidth/orders/e911s/E911ViewPage';
import BandwidthTnOptionOrderViewPage from '../bandwidth/orders/tn-options/TnOptionViewPage';
import BandwidthLidbOrderViewPage from '../bandwidth/orders/lidbs/LidbViewPage';
import BandwidthMoveNumberOrderViewPage from '../bandwidth/orders/move-numbers/MoveNumberViewPage';

import { BANDWIDTH_LOCATION_CREATE_URL, BANDWIDTH_LOCATION_INDEX_URL, BANDWIDTH_LOCATION_UPDATE_URL, BANDWIDTH_LOCATION_VIEW_URL, BANDWIDTH_NUMBER_INDEX_URL, BANDWIDTH_NUMBER_MOVE_URL, BANDWIDTH_NUMBER_UPDATE_E911_URL, BANDWIDTH_NUMBER_UPDATE_GENERAL_URL, BANDWIDTH_NUMBER_UPDATE_LIDB_URL, BANDWIDTH_NUMBER_UPDATE_PASSCODE_URL, BANDWIDTH_NUMBER_UPDATE_ROUTING_URL, BANDWIDTH_NUMBER_VIEW_URL, BANDWIDTH_ORDER_E911S_INDEX_URL, BANDWIDTH_ORDER_E911_VIEW_URL, BANDWIDTH_ORDER_INDEX_URL, BANDWIDTH_ORDER_LIDBS_INDEX_URL, BANDWIDTH_ORDER_LIDB_VIEW_URL, BANDWIDTH_ORDER_MOVES_INDEX_URL, BANDWIDTH_ORDER_MOVE_NUMBER_VIEW_URL, BANDWIDTH_ORDER_NEW_NUMBERS_INDEX_URL, BANDWIDTH_ORDER_NEW_NUMBER_VIEW_URL, BANDWIDTH_ORDER_PORT_INS_INDEX_URL, BANDWIDTH_ORDER_PORT_IN_VIEW_URL, BANDWIDTH_ORDER_TN_OPTIONS_INDEX_URL, BANDWIDTH_ORDER_TN_OPTION_VIEW_URL, BANDWIDTH_PORT_CHECK_URL, BANDWIDTH_SUBACCOUNT_INDEX_URL, BANDWIDTH_SUBACCOUNT_VIEW_URL } from '../bandwidth/urls';

import DubberAccountIndexPage from '../dubber/accounts/AccountIndexPage';
import DubberAccountViewPage from '../dubber/accounts/AccountViewPage';
import DubberUserViewPage from '../dubber/users/UserViewPage';
import DubberUserCreatePage from '../dubber/users/UserCreatePage';
import DubberUserUpdatePage from '../dubber/users/UserUpdatePage';
import DubberTeamViewPage from '../dubber/teams/TeamViewPage';
import DubberTeamCreatePage from '../dubber/teams/TeamCreatePage';
import DubberTeamMemberCreatePage from '../dubber/team-members/TeamMemberCreatePage';
import DubberTeamMemberViewPage from '../dubber/team-members/TeamMemberViewPage';
import DubberTeamMemberUpdatePage from '../dubber/team-members/TeamMemberUpdatePage';
import DubberDubPointCreatePage from '../dubber/dub-points/DubPointCreatePage';
import DubberDubPointUpdatePage from '../dubber/dub-points/DubPointUpdatePage';
import DubberDubPointViewPage from '../dubber/dub-points/DubPointViewPage';
import DubberUnidentifiedDubPointIndexPage from '../dubber/dub-points/unidentified/UnidentifiedDubPointIndexPage';
import DubberUnidentifiedDubPointViewPage from '../dubber/dub-points/unidentified/UnidentifiedDubPointViewPage';
import DubberUnidentifiedDubPointUpdatePage from '../dubber/dub-points/unidentified/UnidentifiedDubPointUpdatePage';

import { DIRECT_ROUTING_ASSIGNMENT_UPDATE_URL, DIRECT_ROUTING_ASSIGNMENT_VIEW_URL } from '../direct-routing/urls';
import DirectRoutingAssignmentViewPage from '../direct-routing/assignment/AssignmentViewPage';
import DirectRoutingAssignmentUpdatePage from '../direct-routing/assignment/AssignmentUpdatePage';
import DirectRoutingOrganizationIndexPage from '../direct-routing/organizations/OrganizationIndexPage';

import { FAX2MAIL_ASSIGNMENT_VIEW_URL, FAX2MAIL_ORGANIZATION_INDEX_URL, FAX2MAIL_ASSIGNMENT_UPDATE_URL, FAX2MAIL_SEAT_URL, FAX2MAIL_STATEMENT_INDEX_URL, FAX2MAIL_ASSIGNMENT_STATEMENTS_URL, FAX2MAIL_STATEMENT_CREATE_URL } from '../fax2mail/urls';
import Fax2MailOrganizationIndexPage from '../fax2mail/organizations/OrganizationIndexPage';
import Fax2MailAssignmentView from '../fax2mail/assignment/AssignmentViewPage';
import Fax2MailSeatUpdatePage from '../fax2mail/seats/SeatUpdatePage';
import Fax2MailStatementsIndexPage from '../fax2mail/statements/StatementsIndexPage';
import Fax2MailStatementsCreatePage from '../fax2mail/statements/StatementCreatePage';

import AssignmentUpdatePage from '../fax2mail/assignment/AssignmentUpdatePage';
import HostedPbxUpdatePage from '../hosted-pbx/assignment/HostedPbxUpdatePage';
import HostedPbxViewPage from '../hosted-pbx/assignment/HostedPbxViewPage';
import BulkUploadIndexPage from '../hosted-pbx/bulk-uploads/BulkUploadIndexPage';
import NumberIndexPage from '../hosted-pbx/numbers/NumberIndexPage';
import ZeroTouchIndexPage from '../hosted-pbx/zero-touch/ZeroTouchIndexPage';
import HostedPbxOrganizationsIndexPage from '../hosted-pbx/organizations/OrganizationsIndexPage';
import {
    HPBX_ASSIGNMENT_STATEMENTS_URL,
    HPBX_ASSIGNMENT_UPDATE_URL,
    HPBX_ASSIGNMENT_VIEW_URL, HPBX_BULK_UPLOADS_INDEX_URL,
    HPBX_NINE_LINE_INDEX_URL,
    HPBX_NUMBERS_INDEX_URL, HPBX_ORGANIZATIONS_INDEX_URL,
    HPBX_STATEMENTS_INDEX_URL,
    HPBX_ZERO_TOUCH_INDEX_URL
} from '../hosted-pbx/urls';

import OperatorConnectAssignmentViewPage from '../operator-connect/assignment/AssignmentViewPage';
import OperatorConnectAssignmentUpdatePage from '../operator-connect/assignment/AssignmentUpdatePage';
import OperatorConnectOrganizationIndexPage from '../operator-connect/organizations/OrganizationIndexPage';


import ServiceAssignmentWizardPage from '../organizations/assign-service/ServiceAssignmentWizardPage';
import OrganizationCreatePage from '../organizations/create/OrganizationCreatePage';
import OrganizationUpdatePage from '../organizations/update/OrganizationUpdatePage';
import {
    ORGANIZATION_ASSIGN_SERVICE_URL,
    ORGANIZATION_CREATE_URL,
    ORGANIZATION_INDEX_URL,
    ORGANIZATION_UPDATE_URL,
    ORGANIZATION_VIEW_URL
} from '../organizations/urls';
import DidUsagePage from '../reporting/DidUsagePage';
import HostedPbxUsagePage from '../reporting/HostedPbxUsagePage';
import { REPORTING_DID_USAGE_URL, REPORTING_HPBX_USAGE_URL } from '../reporting/urls';

import SipTrunkingOrganizationsIndexPage from '../sip-trunking/organizations/OrganizationIndexPage';

import SipTrunkingAssignmentViewPage from '../sip-trunking/assignment/AssignmentViewPage';
import SipTrunkingAssignmentUpdatePage from '../sip-trunking/assignment/AssignmentUpdatePage';

import { USER_PROFILE_UPDATE_URL, USER_PROFILE_URL } from '../user-profile/urls';
import UserProfilePage from '../user-profile/UserProfilePage';
import UserProfileUpdatePage from '../user-profile/UserProfileUpdatePage';

import { USER_CREATE_URL, USER_INDEX_URL, USER_UPDATE_URL } from '../users/urls';
import UserCreatePage from '../users/UserCreatePage';
import UserUpdatePage from '../users/UserUpdatePage';
import { SIP_TRUNKING_ASSIGNMENT_VIEW_URL, SIP_TRUNKING_ORGANIZATION_INDEX_URL, SIP_TRUNKING_ASSIGNMENT_UPDATE_URL } from '../sip-trunking/urls';
import { DUBBER_ACCOUNT_INDEX_URL, DUBBER_ACCOUNT_VIEW_URL, DUBBER_DUBPOINT_CREATE_URL, DUBBER_DUBPOINT_INDEX_URL, DUBBER_DUBPOINT_UPDATE_URL, DUBBER_DUBPOINT_VIEW_URL, DUBBER_TEAM_CREATE_URL, DUBBER_TEAM_INDEX_URL, DUBBER_TEAM_MEMBER_CREATE_URL, DUBBER_TEAM_MEMBER_UPDATE_URL, DUBBER_TEAM_MEMBER_VIEW_URL, DUBBER_TEAM_VIEW_URL, DUBBER_UNIDENTIFIED_DUBPOINT_INDEX_URL, DUBBER_UNIDENTIFIED_DUBPOINT_UPDATE_URL, DUBBER_UNIDENTIFIED_DUBPOINT_VIEW_URL, DUBBER_USER_CREATE_URL, DUBBER_USER_INDEX_URL, DUBBER_USER_UPDATE_URL, DUBBER_USER_VIEW_URL } from '../dubber/urls';
import PortCheckPage from '../bandwidth/port-check/PortCheckPage';
import { WEBEX_ASSIGNMENT_UPDATE_URL, WEBEX_ASSIGNMENT_VIEW_URL, WEBEX_BULK_UPLOADS_INDEX_URL, WEBEX_ORGANIZATION_INDEX_URL, WEBEX_REDSKY_INDEX_URL } from '../webex/urls';

import WebexOrganizationIndexPage from '../webex/organizations/OrganizationIndexPage';
import WebexAssignmentView from '../webex/assignment/AssignmentViewPage';
import WebexAssignmentUpdatePage from '../webex/assignment/AssignmentUpdatePage';
import WebexBulkUploadIndexPage from '../webex/bulk-uploads/BulkUploadIndexPage';
import WebexRedSkyIndexPage from '../webex/redsky/RedSkyIndexPage';

import { DIRECT_ROUTING_ORGANIZATION_INDEX_URL } from '../direct-routing/urls';
import NineLineIndexPage from '../hosted-pbx/9line/NineLineIndexPage';
import { OPERATOR_CONNECT_ASSIGNMENT_UPDATE_URL, OPERATOR_CONNECT_ASSIGNMENT_VIEW_URL, OPERATOR_CONNECT_ORGANIZATION_INDEX_URL } from '../operator-connect/urls';

const Routes = () => {
    return (
        <Switch>
            {/* Login doesn't show thw app window */}
            <Route exact path={LOGIN_URL} component={LoginPage} />
            <Route exact path={LOGIN_MFA_URL} component={MfaPage} />

            <AppWindow>
                <Switch>
                    {/* Dashboard */}
                    <Route exact path={DASHBOARD_URL} component={DashboardPage}/>

                    {/* Administrators */}
                    <Route exact path={ADMINISTRATOR_INDEX_URL} component={AdministratorIndexPage}/>
                    <Route exact path={ADMINISTRATOR_CREATE_URL} component={AdministratorCreatePage}/>
                    <Route exact path={ADMINISTRATOR_VIEW_URL} component={AdministratorViewPage}/>
                    <Route exact path={ADMINISTRATOR_UPDATE_URL} component={AdministratorUpdatePage} />

                    {/* Bandwidth Tools */}
                    <Route exact path={BANDWIDTH_SUBACCOUNT_INDEX_URL} component={BandwidthSubAccountIndexPage} />
                    <Route exact path={BANDWIDTH_SUBACCOUNT_VIEW_URL} component={BandwidthSubAccountViewPage} />
                    <Route exact path={BANDWIDTH_LOCATION_INDEX_URL} component={BandwidthLocationIndexPage} />
                    <Route exact path={BANDWIDTH_LOCATION_CREATE_URL} component={BandwidthLocationCreatePage} />
                    <Route exact path={BANDWIDTH_LOCATION_VIEW_URL} component={BandwidthLocationViewPage} />
                    <Route exact path={BANDWIDTH_LOCATION_UPDATE_URL} component={BandwidthLocationUpdatePage} />
                    <Route exact path={BANDWIDTH_NUMBER_INDEX_URL} component={BandwidthNumberIndexPage} />
                    <Route exact path={BANDWIDTH_NUMBER_VIEW_URL} component={BandwidthNumberViewPage} />

                    <Route exact path={BANDWIDTH_NUMBER_UPDATE_GENERAL_URL} component={BandwidthNumberGeneralUpdatePage} />
                    <Route exact path={BANDWIDTH_NUMBER_UPDATE_ROUTING_URL} component={BandwidthNumberRoutingUpdatePage} />
                    <Route exact path={BANDWIDTH_NUMBER_UPDATE_PASSCODE_URL} component={BandwidthNumberPasscodeUpdatePage} />
                    <Route exact path={BANDWIDTH_NUMBER_UPDATE_E911_URL} component={BandwidthNumberE911UpdatePage} />
                    <Route exact path={BANDWIDTH_NUMBER_UPDATE_LIDB_URL} component={BandwidthNumberOutboundCallerIdUpdatePage} />
                    <Route exact path={BANDWIDTH_NUMBER_MOVE_URL} component={BandwidthNumberMovePage} />

                    <Route exact path={BANDWIDTH_ORDER_INDEX_URL} component={BandwidthOrderIndexPage} />
                    <Route exact path={BANDWIDTH_ORDER_NEW_NUMBERS_INDEX_URL} render={props => <BandwidthOrderIndexPage activeTab="new-numbers" {...props} />} />
                    <Route exact path={BANDWIDTH_ORDER_PORT_INS_INDEX_URL} render={props => <BandwidthOrderIndexPage activeTab="port-ins" {...props} />} />
                    <Route exact path={BANDWIDTH_ORDER_E911S_INDEX_URL} render={props => <BandwidthOrderIndexPage activeTab="e911s" {...props} />} />
                    <Route exact path={BANDWIDTH_ORDER_TN_OPTIONS_INDEX_URL} render={props => <BandwidthOrderIndexPage activeTab="tn-options" {...props} />} />
                    <Route exact path={BANDWIDTH_ORDER_MOVES_INDEX_URL} render={props => <BandwidthOrderIndexPage activeTab="moves" {...props} />} />
                    <Route exact path={BANDWIDTH_ORDER_LIDBS_INDEX_URL} render={props => <BandwidthOrderIndexPage activeTab="lidbs" {...props} />} />

                    <Route exact path={BANDWIDTH_ORDER_NEW_NUMBER_VIEW_URL} component={BandwidthNewNumberOrderViewPage} />
                    <Route exact path={BANDWIDTH_ORDER_PORT_IN_VIEW_URL} component={BandwidthPortInOrderViewPage} />
                    <Route exact path={BANDWIDTH_ORDER_E911_VIEW_URL} component={BandwidthE911OrderViewPage} />
                    <Route exact path={BANDWIDTH_ORDER_TN_OPTION_VIEW_URL} component={BandwidthTnOptionOrderViewPage} />
                    <Route exact path={BANDWIDTH_ORDER_LIDB_VIEW_URL} component={BandwidthLidbOrderViewPage} />
                    <Route exact path={BANDWIDTH_ORDER_MOVE_NUMBER_VIEW_URL} component={BandwidthMoveNumberOrderViewPage} />

                    <Route exact path={BANDWIDTH_PORT_CHECK_URL} component={PortCheckPage} />

                    {/* Direct Routing */}
                    <Route exact path={DIRECT_ROUTING_ORGANIZATION_INDEX_URL} component={DirectRoutingOrganizationIndexPage} />
                    <Route exact path={DIRECT_ROUTING_ASSIGNMENT_VIEW_URL} component={DirectRoutingAssignmentViewPage} />
                    <Route exact path={DIRECT_ROUTING_ASSIGNMENT_UPDATE_URL} component={DirectRoutingAssignmentUpdatePage} />

                    {/* Dubber */}
                    <Route exact path={DUBBER_ACCOUNT_INDEX_URL} component={DubberAccountIndexPage} />

                    <Route exact path={DUBBER_UNIDENTIFIED_DUBPOINT_INDEX_URL} component={DubberUnidentifiedDubPointIndexPage} />
                    <Route exact path={DUBBER_UNIDENTIFIED_DUBPOINT_VIEW_URL} component={DubberUnidentifiedDubPointViewPage} />
                    <Route exact path={DUBBER_UNIDENTIFIED_DUBPOINT_UPDATE_URL} component={DubberUnidentifiedDubPointUpdatePage} />

                    <Route exact path={DUBBER_ACCOUNT_VIEW_URL} component={DubberAccountViewPage} />

                    <Route exact path={DUBBER_USER_INDEX_URL} component={DubberAccountViewPage} />
                    <Route exact path={DUBBER_USER_CREATE_URL} component={DubberUserCreatePage} />
                    <Route exact path={DUBBER_USER_VIEW_URL} component={DubberUserViewPage} />
                    <Route exact path={DUBBER_USER_UPDATE_URL} component={DubberUserUpdatePage} />

                    <Route exact path={DUBBER_TEAM_INDEX_URL} render={props => <DubberAccountViewPage activeTab="teams" {...props} />} />
                    <Route exact path={DUBBER_TEAM_CREATE_URL} component={DubberTeamCreatePage} />
                    <Route exact path={DUBBER_TEAM_VIEW_URL} component={DubberTeamViewPage} />

                    <Route exact path={DUBBER_TEAM_MEMBER_CREATE_URL} component={DubberTeamMemberCreatePage} />
                    <Route exact path={DUBBER_TEAM_MEMBER_VIEW_URL} component={DubberTeamMemberViewPage} />
                    <Route exact path={DUBBER_TEAM_MEMBER_UPDATE_URL} component={DubberTeamMemberUpdatePage} />

                    <Route exact path={DUBBER_DUBPOINT_INDEX_URL} render={props => <DubberAccountViewPage activeTab="dub-points" {...props} />} />
                    <Route exact path={DUBBER_DUBPOINT_CREATE_URL} component={DubberDubPointCreatePage}/>
                    <Route exact path={DUBBER_DUBPOINT_UPDATE_URL} component={DubberDubPointUpdatePage}/>
                    <Route exact path={DUBBER_DUBPOINT_VIEW_URL} component={DubberDubPointViewPage} />

                    {/* Fax2Mail */}
                    <Route exact path={FAX2MAIL_ORGANIZATION_INDEX_URL} component={Fax2MailOrganizationIndexPage} />
                    <Route exact path={FAX2MAIL_SEAT_URL} component={Fax2MailSeatUpdatePage} />
                    <Route exact path={FAX2MAIL_STATEMENT_INDEX_URL} component={Fax2MailStatementsIndexPage} />
                    <Route exact path={FAX2MAIL_STATEMENT_CREATE_URL} component={Fax2MailStatementsCreatePage} />
                    <Route exact path={FAX2MAIL_ASSIGNMENT_VIEW_URL} component={Fax2MailAssignmentView} />
                    <Route exact path={FAX2MAIL_ASSIGNMENT_STATEMENTS_URL} render={props => <Fax2MailAssignmentView activeTab="statements" {...props} />} />
                    <Route exact path={FAX2MAIL_ASSIGNMENT_UPDATE_URL} component={AssignmentUpdatePage} />

                    {/* Operator Connect */}
                    <Route exact path={OPERATOR_CONNECT_ORGANIZATION_INDEX_URL} component={OperatorConnectOrganizationIndexPage} />
                    <Route exact path={OPERATOR_CONNECT_ASSIGNMENT_VIEW_URL} component={OperatorConnectAssignmentViewPage} />
                    <Route exact path={OPERATOR_CONNECT_ASSIGNMENT_UPDATE_URL} component={OperatorConnectAssignmentUpdatePage} />

                    {/* Organizations */}
                    <Route exact path={ORGANIZATION_INDEX_URL} component={OrganizationIndexPage}/>
                    <Route exact path={ORGANIZATION_CREATE_URL} component={OrganizationCreatePage}/>
                    <Route exact path={ORGANIZATION_VIEW_URL} component={OrganizationViewPage}/>
                    <Route exact path={ORGANIZATION_UPDATE_URL} component={OrganizationUpdatePage}/>
                    <Route exact path={ORGANIZATION_ASSIGN_SERVICE_URL} component={ServiceAssignmentWizardPage}/>

                    {/* Organizations - Users */}
                    <Route exact path={USER_INDEX_URL} render={props => <OrganizationViewPage activeTab="users" {...props} />}/>
                    <Route exact path={USER_CREATE_URL} component={UserCreatePage}/>
                    <Route exact path={USER_UPDATE_URL} component={UserUpdatePage}/>

                    {/* Hosted PBX */}
                    <Route exact path={HPBX_ORGANIZATIONS_INDEX_URL} component={HostedPbxOrganizationsIndexPage}/>

                    <Route exact path={HPBX_ASSIGNMENT_VIEW_URL} component={HostedPbxViewPage}/>
                    <Route exact path={HPBX_ASSIGNMENT_UPDATE_URL} component={HostedPbxUpdatePage}/>

                    <Route exact path={HPBX_NINE_LINE_INDEX_URL} component={NineLineIndexPage} />

                    <Route exact path={HPBX_BULK_UPLOADS_INDEX_URL} component={BulkUploadIndexPage} />

                    <Route exact path={HPBX_NUMBERS_INDEX_URL} component={NumberIndexPage} />

                    <Route exact path={HPBX_ZERO_TOUCH_INDEX_URL} component={ZeroTouchIndexPage} />

                    {/* Reporting */}
                    <Route exact path={REPORTING_DID_USAGE_URL} component={DidUsagePage}/>
                    <Route exact path={REPORTING_HPBX_USAGE_URL} component={HostedPbxUsagePage} />

                    {/* SIP Trunking */}
                    <Route exact path={SIP_TRUNKING_ORGANIZATION_INDEX_URL} component={SipTrunkingOrganizationsIndexPage} />

                    <Route exact path={SIP_TRUNKING_ASSIGNMENT_VIEW_URL} component={SipTrunkingAssignmentViewPage} />
                    <Route exact path={SIP_TRUNKING_ASSIGNMENT_UPDATE_URL} component={SipTrunkingAssignmentUpdatePage} />

                    {/* User Profile */}
                    <Route exact path={USER_PROFILE_URL} component={UserProfilePage} />
                    <Route exact path={USER_PROFILE_UPDATE_URL} component={UserProfileUpdatePage} />

                    {/* Webex */}
                    <Route exact path={WEBEX_ORGANIZATION_INDEX_URL} component={WebexOrganizationIndexPage} />
                    <Route exact path={WEBEX_ASSIGNMENT_VIEW_URL} component={WebexAssignmentView} />
                    <Route exact path={WEBEX_ASSIGNMENT_UPDATE_URL} component={WebexAssignmentUpdatePage} />

                    <Route exact path={WEBEX_BULK_UPLOADS_INDEX_URL} component={WebexBulkUploadIndexPage} />

                    <Route exact path={WEBEX_REDSKY_INDEX_URL} component={WebexRedSkyIndexPage} />
                </Switch>
            </AppWindow>
        </Switch>
    );
};

export default Routes;