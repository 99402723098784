import { DubPointBase, Language, UserRole } from "./api";
import { ALL_TEAM_NAME, DubPointPlatform, HPBX_SERVICE_PROVIDERS, PLATFORM_PRODUCTS, PRODUCT_CALL_RECORDING, PRODUCT_CALL_RECORDING_WITH_AI, PRODUCT_DUBBER_GO, PRODUCT_DUBBER_PREMIER, PRODUCT_DUBBER_TEAMS } from "./constants";
import { Team } from "./teams/api";

export const roleLabel = (role: UserRole) => {
    switch (role) {
        case 'StandardUser': return 'Standard User';
        default: return role;
    }
}

export const languageLabel = (language: Language) => {
    switch (language) {
        case 'en-US': return 'English (United States)';
        case 'en': return 'English (Great Britain)';
        case 'de': return 'Deutsch';
        case 'el': return 'Ελληνικά';
        case 'es': return 'Español';
        case 'fr': return 'Français';
        case 'it': return 'Italiano';
        case 'ja': return '日本語';
        case 'nl': return 'Nederlands';
        case 'pl': return 'Język Polski';
        case 'pt': return 'Língua Portuguesa';
        case 'sv': return 'Svenska';
        case 'zh-CN': return '简体中文';
        case 'zh-TW': return '繁體中文';
    }
};

// Get the platform (Hosted PBX, Cloud PBX, Teams) that the dub point targets
export const platformForDubPoint = (dubPoint: DubPointBase): DubPointPlatform | undefined => {
    switch (dubPoint.externalType) {
        case 'microsoft': return 'Teams';
        case 'broadworks': return HPBX_SERVICE_PROVIDERS.indexOf(dubPoint.serviceProvider) > -1 ? 'HostedPBX' : 'Webex';
        default: return undefined;
    }
};

// Get the products that can be assigned to a dub point based on the platform the dub point targets
export const productOptionsForDubPoint = (dubPoint: DubPointBase) => {
    const service = platformForDubPoint(dubPoint);

    if (service !== undefined && PLATFORM_PRODUCTS[service]) {
        return PLATFORM_PRODUCTS[service];
    }

    return [];
}

export const productLabel = (product: string) => {
    switch (product) {
        // Hosted PBX products
        case PRODUCT_CALL_RECORDING: return 'Call Recording';
        case PRODUCT_CALL_RECORDING_WITH_AI: return 'Call Recording with AI';

        // Teams products
        case PRODUCT_DUBBER_TEAMS: return 'Dubber Teams';
        case PRODUCT_DUBBER_PREMIER: return 'Dubber Premier';

        // Webex products
        case PRODUCT_DUBBER_GO: return 'Dubber GO';

        default: return product;
    }
};

export const isAllTeam = (team: Team) => team.name === ALL_TEAM_NAME;