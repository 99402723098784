import { Service } from "../../services";

// The name of the Team that's created by default that holds all users and dub points
export const ALL_TEAM_NAME = "All";

export const PRODUCT_RESERVED = 'Reserved';
export const PRODUCT_CALL_RECORDING = 'CDU-T-01';
export const PRODUCT_CALL_RECORDING_WITH_AI = 'DAI-T-01';

export const PRODUCT_DUBBER_TEAMS = 'UCRT-T-01';
export const PRODUCT_DUBBER_PREMIER = 'UCRP-T-01';

export const PRODUCT_DUBBER_GO = 'DGO-I-01';

export const PRODUCTS_WITH_AI = [
    PRODUCT_CALL_RECORDING_WITH_AI,
    PRODUCT_DUBBER_PREMIER
];

export const TYPE_API = 'Api';
export const TYPE_RECORDER = 'Recorder';
export const TYPE_UNIDENTIFIED = 'Unidentified';

export type Product =
    typeof PRODUCT_RESERVED
    | typeof PRODUCT_CALL_RECORDING
    | typeof PRODUCT_CALL_RECORDING_WITH_AI
    | typeof PRODUCT_DUBBER_GO
    | typeof PRODUCT_DUBBER_TEAMS
    | typeof PRODUCT_DUBBER_PREMIER;

export const HPBX_SERVICE_PROVIDERS = [
    'ManagedVoice',
    'AllCoveredSP'
];

export type DubPointPlatform =
    'Webex'
    | 'HostedPBX'
    | 'Teams';

interface PlatformProducts {
    [key: string | DubPointPlatform]: Product[];
}

export const PLATFORM_PRODUCTS: PlatformProducts = {
    'HostedPBX': [
        PRODUCT_CALL_RECORDING,
        PRODUCT_CALL_RECORDING_WITH_AI,
        PRODUCT_RESERVED
    ],

    'Teams': [
        PRODUCT_DUBBER_TEAMS,
        PRODUCT_DUBBER_PREMIER,
        PRODUCT_RESERVED
    ],

    'Webex': [
        PRODUCT_DUBBER_GO,
        PRODUCT_DUBBER_TEAMS,
        PRODUCT_DUBBER_PREMIER,
        PRODUCT_RESERVED
    ]
}