import { Block, Check } from '@mui/icons-material';
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon, ListItemText, Typography
} from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../../../api';
import { CancelBandwidthCheck, CancelDubberCheck } from './api';
import { useCancelCheckOperatorConnectAssignment, useCancelOperatorConnectAssignment } from './hooks';

interface Props {
    isOpen: boolean;
    organizationId: number;
    organizationName: string;
    assignmentId: number;
    onCancel: () => void;
    onComplete: () => void;
}

/**
 * Displays a dialog box that allows a manager to cancel Operator Connect services for an organization
 *
 * @param props
 * @constructor
 */
const CancelDialog = (props: Props) => {
    const api = useApi();
    const dispatch = useDispatch();

    const {
        isOpen,
        organizationId,
        organizationName,
        assignmentId,
        onCancel,
        onComplete
    } = props;

    const [cancelCheck, isChecking, checkResults, checkErrors] = useCancelCheckOperatorConnectAssignment();
    const [cancel, isCancelling, isCancelled, cancelErrors] = useCancelOperatorConnectAssignment();

    useEffect(() => {
        if (isOpen) {
            cancelCheck(organizationId, assignmentId);
        }
    }, [isOpen]);

    const handleCancelButton = () => {
        onCancel();
    };

    const handleConfirm = () => {
        if (!isCancelling) {
            cancel(organizationId, assignmentId);
        }
    };

    const handleExited = () => {
    };

    useEffect(() => {
        if (isCancelled) {
            onComplete();
        }
    }, [isCancelled]);

    const error = checkErrors || cancelErrors;

    return (
        <Dialog
            fullScreen={false}
            open={isOpen}
            onClose={handleCancelButton}
            TransitionProps={{
                onExited: handleExited
            }}
            aria-labelledby="cancellation-dialog-title">
            <DialogTitle id="cancellation-dialog-title">Cancel Service</DialogTitle>
            <DialogContent>

                <Typography>
                    You are about to cancel Calling for MS Teams service for <strong>{organizationName}</strong>.
                    This will remove the Bandwidth Sub-Account and Dubber Account.</Typography>

                {error && <Typography variant="body1" color="error">{error}</Typography>}

                {!isChecking && checkResults &&
                    <List>
                        <BandwidthListItem bandwidth={checkResults.bandwidth} />
                        {checkResults.dubber && <DubberListItem dubber={checkResults.dubber} />}
                    </List>
                }
            </DialogContent>

            {!isChecking && checkResults &&
                <DialogActions>
                    <Button onClick={handleCancelButton} color="primary" disabled={isCancelling}>Close</Button>
                    <Button onClick={handleConfirm} color="secondary" autoFocus disabled={isCancelling || !checkResults.success}>
                        Confirm Removal
                    </Button>
                </DialogActions>
            }

            {(isChecking || isCancelling) && <LinearProgress variant="indeterminate" color="primary" />}
        </Dialog>
    );
};

// Provides a generic list item to be used to display what items get affected during a service cancellation

interface ServiceListItemProps {
    serviceName: string;
    text: React.ReactNode;
    success: boolean;
}

const ActionListItem = (props: ServiceListItemProps) => {
    return (
        <ListItem>
            {props.success && <ListItemIcon><Check color="primary" /></ListItemIcon>}
            {!props.success && <ListItemIcon><Block color="error" /></ListItemIcon>}
            <ListItemText primary={props.serviceName} secondary={props.text} />
        </ListItem>
    );
};

// List item showing how the Bandwidth sub-account details and if it can be removed.

interface BandwidthListItemProps {
    bandwidth: CancelBandwidthCheck;
}

const BandwidthListItem = (props: BandwidthListItemProps) => {
    const { bandwidth } = props;

    return (
        <ActionListItem serviceName="Bandwidth Sub-Account" success={bandwidth.success} text={
            <>
                {!bandwidth.accountExists
                    ? <>Sub-Account has already been deleted.</>
                    : <>
                        {!bandwidth.success
                            ? <><strong>{bandwidth.subAccountId} - {bandwidth.subAccountName}</strong> ({bandwidth.locationCount} locations) cannot be removed because it still has <strong>{bandwidth.numberCount}</strong> numbers in service.</>
                            : <><strong>{bandwidth.subAccountId} - {bandwidth.subAccountName}</strong> ({bandwidth.locationCount} locations) will be removed.</>
                        }
                    </>
                }
            </>
        } />
    );
};

// List item showing the Dubber Account details and if it can be removed.

interface DubberListItemProps {
    dubber: CancelDubberCheck;
}

const DubberListItem = (props: DubberListItemProps) => {
    const { dubber } = props;

    return (
        <ActionListItem serviceName="Dubber Account" success={dubber.success} text={
            <>
                {!dubber.accountExists
                    ? <> Account has already been deleted.</>
                    : <>
                        {!dubber.success
                            ? <><strong>{dubber.id} - {dubber.name}</strong> still has <strong>{dubber.userCount}</strong> users. Delete all users before proceeding.</>
                            : <><strong>{dubber.id} - {dubber.name}</strong> is ready.</>
                        }
                    </>
                }
            </>
        } />
    );
};

export default CancelDialog;