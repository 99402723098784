import { Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Input, InputLabel, LinearProgress, Radio, RadioGroup, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect } from 'react';
import { FieldError } from '../../../api';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { OrganizationDetails } from '../../organizations/api';
import { CreateAssignment } from './api';
import { useFetchOperatorConnectBandwidthSubAccounts } from '../hooks';
import { useFetchOperatorConnectDubberAccounts } from '../dubber-accounts/hooks';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    formValues: CreateAssignment,
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    setFormValues: (form: CreateAssignment) => void;
}

export const formComplete = (organization: OrganizationDetails, form: CreateAssignment): boolean => {
    return form.bandwidthSubAccountId != null
        && (
            !form.useDubber || (form.dubberAccountId?.length || 0) > 0
        );
};

/**
 * Operator Connect assignment form
 * @param props
 * @constructor
 */
const CreateAssignmentForm = (props: Props) => {
    const { errorMessage, fieldErrorMessages, formValues, setFormValues, isSubmitting } = props;
    const classes = useStyles();

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);
    const [fetchSubAccounts, isFetchingSubAccounts, subAccounts, fetchSubAccountsError] = useFetchOperatorConnectBandwidthSubAccounts();
    const [fetchDubberAccounts, isFetchingDubberAccounts, dubberAccounts, dubberAccountsError] = useFetchOperatorConnectDubberAccounts();


    useEffect(() => {
        fetchSubAccounts();
        fetchDubberAccounts();
    }, [])

    const handleFieldChange = (fieldName: keyof CreateAssignment, value: number | string | null | boolean) => {
        setFormValues({
            ...formValues,
            [fieldName]: value
        });
    };

    const displayError = errorMessage || fetchSubAccountsError || dubberAccountsError;

    return (
        <>
            {displayError && <Typography variant="body1" color="error">{displayError}</Typography>}

            {!isFetchingSubAccounts && subAccounts !== null && !isFetchingDubberAccounts && dubberAccounts !== null 
                ?
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl className={classes.formControl} fullWidth required error={!isValid('bandwidthSubAccountId')}>
                                <InputLabel htmlFor="bandwidthSubAccountId">Bandwidth Sub-Account</InputLabel>
                                <Select
                                    id="bandwidthSubAccountId"
                                    native={true}
                                    margin="none"
                                    disabled={isSubmitting}
                                    value={formValues.bandwidthSubAccountId || ''}
                                    input={<Input name="bandwidthSubAccountId" id="bandwidthSubAccountId" />}
                                    onChange={evt => handleFieldChange('bandwidthSubAccountId', evt.target.value ? parseInt(String(evt.target.value)) : null)}>
                                    <option key="" value="" />

                                    {subAccounts.map(c => {
                                        return <option key={c.id} value={c.id}>{c.name} ({c.id})</option>;
                                    })}
                                </Select>
                                <ValidationMessage field="bandwidthSubAccountId" />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormControl className={classes.formControl} fullWidth required>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={isSubmitting}
                                            checked={formValues.useDubber}
                                            onChange={(e, checked) => handleFieldChange('useDubber', checked)} />
                                    }
                                    label="Has Call Recording?" />
                            </FormControl>
                        </Grid>


                        {formValues.useDubber &&
                            <Grid item xs={12} sm={12}>
                                <FormControl className={classes.formControl} fullWidth required error={!isValid('dubberAccountId')}>
                                    <InputLabel htmlFor="dubberAccountId">Dubber Account</InputLabel>
                                    <Select
                                        id="dubberAccountId"
                                        native={true}
                                        margin="none"
                                        disabled={isSubmitting}
                                        value={formValues.dubberAccountId ? (formValues.dubberGroupId + '/' + formValues.dubberAccountId) : ''}
                                        input={<Input name="dubberAccountId" id="dubberAccountId" />}
                                        onChange={evt => {
                                            var val = evt.target.value ? String(evt.target.value) : null

                                            if (val === null) {
                                                setFormValues({
                                                    ...formValues,
                                                    dubberGroupId: undefined,
                                                    dubberAccountId: undefined
                                                });
                                            } else {
                                                var split = val.split('/');

                                                setFormValues({
                                                    ...formValues,
                                                    dubberGroupId: split[0],
                                                    dubberAccountId: split[1]
                                                });
                                            }
                                        }}>
                                        <option key="" value="" />

                                        {dubberAccounts.map(c => {
                                            return <option key={c.groupId + '/' + c.id} value={c.groupId + '/' + c.id}>{c.name} ({c.groupId} / {c.id})</option>;
                                        })}
                                    </Select>
                                    <ValidationMessage field="dubberAccountId" />
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                </>
                : <LinearProgress />
            }
        </>
    );
};

export default CreateAssignmentForm;