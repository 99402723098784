import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { useSession } from '../../auth/hooks';
import { authorizedFor } from '../../auth/policies';
import { useFetchOrganizationDetails } from '../../organizations/hooks';
import CancellationNotice from '../../services/CancellationNotice';
import { OperatorConnectPolicies } from '../policies';
import { operatorConnectAssignmentUpdateUrl } from '../urls';
import AssignmentSummary from './AssignmentSummary';
import CancelDialog from './CancelDialog';
import { useFetchOperatorConnectAssignment } from './hooks';
import moment = require('moment');

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto',
    },
    headerButton: {
        marginLeft: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(3)
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props extends RouteComponentProps<any> {
}

/**
 * Operator Connect assignment view page
 * @param props
 * @constructor
 */
const AssignmentViewPage = (props: Props) => {
    const classes = useStyles();
    const session = useSession();
    const dispatch = useDispatch();
    const organizationId = props.match.params['organizationId'];
    const assignmentId = props.match.params['assignmentId'];

    const [isFetchingOrganization, organization, fetchOrganizationError] = useFetchOrganizationDetails(organizationId);
    const [fetch, isFetchingAssignment, assignment, fetchAssignmentError] = useFetchOperatorConnectAssignment();

    const [isCancelConfirmed, setIsCancelConfirmed] = useState(false);

    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

    useNav('operator-connect', 'organizations');

    useProgressEffects(
        isFetchingAssignment || isFetchingOrganization,
        fetchAssignmentError || fetchOrganizationError
    );

    useEffect(() => {
        fetch(organizationId, assignmentId);
    }, [organizationId, assignmentId]);

    const isCancelled = useMemo(() => {
        return !assignment?.isActive;
    }, [assignment]);

    // When cancellation completes, reload the assignment details
    useEffect(() => {
        if (isCancelConfirmed) {
            setCancelDialogOpen(false);
            dispatch(appWindowAddNotification('Calling for MS Teams service cancelled', 'success'));
            fetch(organizationId, assignmentId);
        }
    }, [isCancelConfirmed]);

    return (
        <div className={classes.root}>
            <PageHeader text="Calling for MS Teams"
                subtext={organization !== null ? organization.name : undefined}>

                {organization !== null
                    && !isCancelled
                    && <>
                        {authorizedFor(OperatorConnectPolicies.CanManageAssignments, session.roles) &&
                            <Button
                                className={classes.headerButton}
                                variant="text"
                                color="primary"
                                component={React.forwardRef((props, ref) => <Link to={operatorConnectAssignmentUpdateUrl(organizationId, assignmentId)} {...props} />)}>
                                Update
                            </Button>
                        }

                        {authorizedFor(OperatorConnectPolicies.CanCancelAssignments, session.roles) &&
                            <Button
                                className={classes.headerButton}
                                onClick={() => setCancelDialogOpen(true)}
                                variant="text"
                                color="secondary">
                                Cancel Service
                            </Button>
                        }
                    </>
                }
            </PageHeader>

            {organization !== null
                && assignment !== null
                && <>
                    <div className={classes.marginBottom}>
                        {isCancelled &&
                            <CancellationNotice
                                cancelDate={moment(assignment.cancelledAt).format('MM/DD/YYYY')} />
                        }
                    </div>

                    <div className={classes.marginBottom}>
                        <AssignmentSummary assignment={assignment} />
                    </div>

                    <CancelDialog
                        isOpen={cancelDialogOpen}
                        assignmentId={assignmentId}
                        organizationId={organizationId}
                        organizationName={organization.name}
                        onCancel={() => setCancelDialogOpen(false)}
                        onComplete={() => setIsCancelConfirmed(true)}
                    />
                </>
            }
        </div>
    );
};

export default AssignmentViewPage;