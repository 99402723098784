import { Button, Grid, Paper, Step, StepLabel, Stepper } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { BadRequestError, FieldError, ResponseError, useApi } from '../../../api';
import PageHeader from '../../../components/PageHeader';
import { DIRECT_ROUTING_LIVE, FAX2MAIL_LIVE, MAIN_WIDTH, SIPTRUNKING_LIVE } from '../../../constants';
import history from '../../../history';
import { Service, serviceName, SERVICE_DIRECT_ROUTING, SERVICE_FAX2MAIL, SERVICE_HOSTED_PBX, SERVICE_SIP_TRUNKING, SERVICE_WEBEX, SERVICE_OPERATOR_CONNECT } from '../../../services';
import theme from '../../../theme';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { loginUrl } from '../../auth/urls';
import { addService } from '../api';
import { useFetchAssignedServices, useFetchOrganizationDetails } from '../hooks';
import { OrganizationPolicies } from '../policies';
import { organizationViewUrl } from '../urls';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

interface ServiceOption {
    id: Service;
    label: string;
    disabled: boolean;
}

var serviceOptions: ServiceOption[] = [
    { id: SERVICE_HOSTED_PBX, label: serviceName(SERVICE_HOSTED_PBX), disabled: false },
];

serviceOptions.push({
    id: SERVICE_OPERATOR_CONNECT,
    label: serviceName(SERVICE_OPERATOR_CONNECT),
    disabled: false
});

if (DIRECT_ROUTING_LIVE) {
    serviceOptions.push({
        id: SERVICE_DIRECT_ROUTING,
        label: serviceName(SERVICE_DIRECT_ROUTING),
        disabled: false
    });
}

if (FAX2MAIL_LIVE) {
    serviceOptions.push({
        id: SERVICE_FAX2MAIL,
        label: serviceName(SERVICE_FAX2MAIL),
        disabled: false
    });
}

if (SIPTRUNKING_LIVE) {
    serviceOptions.push({
        id: SERVICE_SIP_TRUNKING,
        label: serviceName(SERVICE_SIP_TRUNKING),
        disabled: false
    });
}

serviceOptions.push({
    id: SERVICE_WEBEX,
    label: serviceName(SERVICE_WEBEX),
    disabled: false
});

interface Props extends RouteComponentProps<any> {
}

/**
 * Wizard for assigning services to an organization
 * @param props
 * @constructor
 */
const ServiceAssignmentWizardPage = (props: Props) => {
    const classes = useStyles();
    const api = useApi();
    const organizationId = props.match.params['id'];

    const dispatch = useDispatch();

    const [step, setStep] = useState(0);
    const [selectedService, setSelectedService] = useState<Service|null>(null);

    const [finish, setFinish] = useState({
        ready: false,
        values: {}
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Error messages for submissions
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [fieldErrorMessages, setFieldErrorMessages] = useState<FieldError[]>([]);

    // Load organization details
    const [isFetching, organization, error] = useFetchOrganizationDetails(organizationId);

    // Get services already assigned to organization
    const [isFetchingServices, services, servicesError] = useFetchAssignedServices(organizationId);

    useNav('organizations');

    useProgressEffects(isFetching || isFetchingServices || isSubmitting, error || servicesError);

    const handlePreviousStep = () => {
        if (step > 0) {
            setStep(step - 1);
        } else {
            // Wizard cancelled
            history.push(organizationViewUrl(organizationId));
        }
    };

    const handleNextStep = () => {
        if (step < 1) {
            setStep(step + 1);
        }
    };

    const handleReady = (ready: boolean, values: {[key: string]: string | boolean | number | number[] | undefined | null}) => {
        setFinish({
            ready,
            values
        })
    };

    const handleFinish = async () => {
        if (selectedService !== null) {
            setIsSubmitting(true);

            try {
                // Send the create request to the API
                await addService(api, organizationId, selectedService, finish.values);

                // Create a notification about the creation
                dispatch(appWindowAddNotification('Service assigned.', 'success'));

                // Forward back to list page
                history.push(organizationViewUrl(organizationId));
            } catch (e) {
                // If the API returns a 401 error, then our session is not valid
                // and we must take the user back to the login screen
                if ((e instanceof ResponseError) && (e.code === 401)) {
                    history.push(loginUrl());
                } else if (e instanceof BadRequestError) {
                    // For bad request errors, display field messages if they're present
                    // Else display the primary error message
                    if (e.fields && e.fields.length > 0) {
                        setErrorMessage(null);
                        setFieldErrorMessages(e.fields);

                    } else {
                        setErrorMessage(e.message);
                        setFieldErrorMessages([]);
                    }

                    setIsSubmitting(false);
                } else {
                    // For all other errors, display a generic message
                    dispatch(appWindowAddNotification('Unable to assign service.', 'error'));
                    setIsSubmitting(false);
                }
            }
        }
    };

    // Disable service options for services already assigned
    let adjustedServiceOptions = useMemo(() => serviceOptions.map(option => {
        let copy = { ...option };

        if (services !== null) {
            for (var i = 0; i < services.length; i++) {
                if (services[i].service === option.id && services[i].isActive) {
                    copy.disabled = true;
                }
            }
        }

        return copy;
    }), [organization, services]);

    return (
        <div className={classes.root}>
            <PageHeader text="Assign Service"
                        subtext={organization !== null ? organization.name : undefined}/>

            {organization !== null &&
            <>
                <Paper className={classes.paper}>
                    <Stepper activeStep={step}>
                        <Step key={0}>
                            <StepLabel>Select Service</StepLabel>
                        </Step>
                        <Step key={1}>
                            <StepLabel>Configure Service</StepLabel>
                        </Step>
                    </Stepper>

                    {step == 0 && <StepOne
                        serviceOptions={adjustedServiceOptions}
                        selectedService={selectedService}
                        onChange={service => setSelectedService(service)}/>
                    }

                    {step == 1 && selectedService !== null && <StepTwo
                        organization={organization}
                        service={selectedService}
                        submitting={isSubmitting}
                        onReady={handleReady}
                        errorMessage={errorMessage}
                        fieldErrorMessages={fieldErrorMessages}/>
                    }
                </Paper>

                <Grid container justifyContent="flex-end">
                    <Button
                        className={classes.button}
                        color="inherit"
                        variant="contained"
                        disabled={isSubmitting}
                        onClick={handlePreviousStep}>
                        {step == 0 && 'Cancel'}
                        {step > 0 && 'Previous'}
                    </Button>

                    {step == 0 &&
                    <Button
                        className={classes.button}
                        disabled={selectedService == null || isSubmitting}
                        color="primary"
                        variant="contained"
                        onClick={handleNextStep}>Next</Button>
                    }

                    {step == 1 &&
                    <Button
                        className={classes.button}
                        disabled={!finish.ready || isSubmitting}
                        color="primary"
                        variant="contained"
                        onClick={handleFinish}>Finish</Button>
                    }
                </Grid>
            </>
            }
        </div>
    );
};

export default withPolicyRestriction(ServiceAssignmentWizardPage, OrganizationPolicies.CanManage);