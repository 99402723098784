import { BILLING_ASSOCIATE, PROVISIONING, SERVICE_MANAGER, SUPER_USER } from '../administrators/api';

export enum OperatorConnectPolicies {
    CanManageAssignments = 'CanManageOperatorConnectAssignments',
    CanCancelAssignments = 'CanCancelOperatorConnectAssignments',
}

export const OperatorConnectPolicyRoles = {
    [OperatorConnectPolicies.CanManageAssignments]: [SUPER_USER, BILLING_ASSOCIATE, SERVICE_MANAGER, PROVISIONING],
    [OperatorConnectPolicies.CanCancelAssignments]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING],
};