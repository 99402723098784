import { ApiCaller } from '../../api';

export type ReportService = 'cloud-pbx' | 'hosted-pbx' | 'operator-connect' | 'sip-trunking';

export const generateActiveDidsReport = async (api: ApiCaller, year: number, month: number, service: ReportService): Promise<string> => {
    const response = await api<{url: string}>(`reporting/did-usage`, 'POST', {
        year,
        month,
        service
    });

    return response.url;
};

export const generateHpbxUsageReport = async (api: ApiCaller, year: number, month: number, groupId: string): Promise<string> => {
    const response = await api<{url: string}>(`reporting/hpbx-usage`, 'POST', {
        year,
        month,
        groupId
    });

    return response.url;
};